import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const readingMapUrl = location.state?.reading_map_url; // Retrieve reading_map_url from state

  useEffect(() => {
    if (!readingMapUrl) {
      alert('No reading map URL provided. Redirecting to the homepage.');
      navigate('/');
      return;
    }

    // Redirect to the reading map page
    navigate(`/maps/${readingMapUrl}`);
  }, [readingMapUrl, navigate]);

  return (
    <div className="flex justify-center items-center h-screen">
      <p className="text-lg font-medium text-gray-700">
        Processing your purchase... Please wait.
      </p>
    </div>
  );
};

export default Success;