import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../utils/supabase';
import { ShareIcon } from '@heroicons/react/24/outline';
import cardDetails from '../../components/assets/information/CardDetails';

const TarotMap = () => {
  const { uniqueId } = useParams(); // Extract the UUID from the URL
  const [sessionData, setSessionData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const { data, error } = await supabase
          .from('guest_sessions')
          .select('*')
          .eq('reading_map_url', uniqueId) // Match against UUID
          .single();

        if (error) {
          console.error('Error fetching session data:', error.message);
          setError(error.message);
        } else {
          setSessionData(data);
        }
      } catch (err) {
        console.error('Unexpected error:', err);
        setError('An unexpected error occurred.');
      }
    };

    fetchSessionData();
  }, [uniqueId]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Your TarotMap',
          text: `Check out my TarotMap reading: ${sessionData?.reading_type}`,
          url: window.location.href,
        });
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };

  if (error) return <div>Error: {error}</div>;
  if (!sessionData) return <div>Loading...</div>;

  return (
    <div className="bg-gray-50 py-10 sm:py-32">
      
      <header>
          <div className="mx-2 place-self-end px-5 sm:px-5 lg:px-5">
             <button
                type="button"
                className="-m-2.5 p-2.5  text-gray-400 hover:text-gray-300"
                onClick={handleShare}
              >
                <span className="sr-only">Share</span>
                <ShareIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
        </header>
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        
      <a href="/"><img alt="TarotMap" src="/favicon.ico" className="mx-auto h-10 w-auto" /></a>

        <h2 className="text-center text-base/7 font-semibold text-neutral-500">
          Your TarotMap
        </h2>
        <p className="mx-auto mt-6 mb-6 text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 sm:text-4xl">
          {sessionData.reading_type}
        </p>
        <p className="mx-auto mt-4 text-center text-sm font-semibold tracking-tight text-balance text-orange-900">
          Reading focus:</p>
        <p className="mx-auto mt-2 text-center text-sm font-semibold tracking-tight text-balance text-gray-800">
         {sessionData.reading_question}
        </p>

        <div className="mt-10 grid gap-4 sm:mt-10 lg:grid-cols-3 lg:grid-rows-auto">
          {sessionData.step_data.map((step, index) => {
            const suitIcon =
              cardDetails[step.selectedSuit]?.icon || null;

            return (
              <div
                key={index}
                className="relative flex lg:row-span-2 flex-col row-auto overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-black/5"
              >
                {/* Step Header */}
                <div className="px-10 mt-6 sm:px-10 sm:pt-10">
                <p className="text-md font-medium text-gray-950">
                    Step {step.step}:
                  </p>
                  
                <div className="flex justify-center mt-6 mb-4">
                {step.stepMap && (
                  <img
                    src={`/${step.stepMap}`} // Ensure the correct path
                    alt={`Layout`}
                    className="max-w-80p"
                  />
                )}
                
              </div>
              <p className="mt-2 text-sm/6 text-gray-600">
                    {step.stepMeaning}
                  </p>      
                 
                </div>
                
       {/* card details */}
         <div className="px-10 mt-6  sm:px-10 sm:pb-10 sm:pt-10">
          <div className="rounded-lg px-10 bg-orange-50/10 p-4 shadow-sm border border-orange-900/5">

        {/* Suit Icon */}
        <div className="flex justify-center mt-6 mb-4">
          {suitIcon && (
            <img
              src={suitIcon}
              alt={`${step.selectedSuit} icon`}
              className="w-8 h-8"
            />
          )}
        </div>

        {/* Card Info */}
        <p className="text-lg font-bold mb-6 text-orange-900 text-center">
          {step.cardDetailsForSelectedCard.name} of{' '}
          {step.cardDetailsForSelectedCard.suit}
        </p>
        <p className="mt-2 mb-2 text-sm text-gray-600 text-center">
          {step.cardDetailsForSelectedCard.meaning}
        </p>
        <p className="mt-2 mb-6 text-sm text-gray-400 text-center">
          Reverse: {step.cardDetailsForSelectedCard.reverse}
        </p>
      </div>
      </div>
      <div className="px-8 mb-12 sm:px-10 sm:pt-6">
                  <p className="mt-6 mb-4 text-sm font-normal text-amber-900/70">
                    Step notes:
                  </p>
                  <p className="px-4 text-sm text-neutral-600">{step.notes}</p>
                </div>
              </div>
            );
          })}

        </div>
      </div>
    </div>
  );
};

export default TarotMap;