
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Cancel = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the reading_map_url from state
  const readingMapUrl = location.state?.reading_map_url;

  // Function to navigate back to finalise.js
  const handleGoBackToFinalise = () => {
    if (readingMapUrl) {
      navigate('/finalise', { state: { reading_map_url: readingMapUrl } });
    } else {
      navigate('/finalise');
    }
  };

  // Function to navigate back to the home page
  const handleNoThanks = () => {
    navigate('/');
  };

  return (
   
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        
      <div className="text-center">
     
    <img alt="TarotMap" src="/favicon.ico" className="mx-auto h-10 w-auto" />

      <h2 className="text-base/7 font-semibold text-neutral-800">TarotMap</h2>
    
        <p className="text-base mt-20 font-semibold text-orange-900">Payment Cancelled</p>
        <h1 className="mt-4 text-4xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
          Are you sure?
        </h1>
        <p className="mt-6 text-sm font-medium text-gray-500 sm:text-xl/8">
          If you change your mind, you can return to complete your purchase. </p> 
          <p className="mt-4 text-sm font-medium text-gray-500 sm:text-xl/8">
          If you exit here, you will not be able to retrieve the TarotMap from this session.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            onClick={handleGoBackToFinalise}
            className="rounded-md bg-orange-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-xs hover:bg-orange-900/70 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-900/70"
          >
            Return to purchase
          </button>
          <button
            onClick={handleNoThanks}
            className="text-sm font-semibold text-gray-900"
          >
            Exit <span aria-hidden="true">&rarr;</span>
          </button>
        </div>
      </div>
    </main>
  );
};

export default Cancel;