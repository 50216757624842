import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ReadingSession from './pages/ReadingSession';
import Finalise from './pages/Finalise';
import TarotMap from './pages/maps/[uniqueId]';
import Success from './pages/success'; 
import Cancel from './pages/cancel'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reading-session" element={<ReadingSession />} />
        <Route path="/finalise" element={<Finalise />} />
        <Route path="/maps/:uniqueId" element={<TarotMap />} />
        <Route path="/success" element={<Success />} /> 
        <Route path="/cancel" element={<Cancel />} /> 
      </Routes>
    </Router>
  );
}

export default App;