import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/20/solid';

const Finalise = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const readingMapUrl = location.state?.reading_map_url;

  const handleViewMap = () => {
    if (readingMapUrl) {
      // Navigate to the unique TarotMap URL
      navigate(`/maps/${readingMapUrl}`);
    } else {
      alert('Error: Unable to locate your TarotMap.');
    }
  };

  const handlePurchase = async () => {
    try {
      // Validate required details
      if (!readingMapUrl || !location.state?.guest_uid) {
        alert('Error: Missing required details for purchase.');
        console.error('Missing data:', { readingMapUrl, guestUID: location.state?.guest_uid });
        return;
      }
  
      // Dynamically set the success URL, embedding the reading map URL
      const successUrl = `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}&reading_map_url=${readingMapUrl}`;
      const cancelUrl = `${window.location.origin}/cancel`;
  
      // Call your backend to create the Stripe Checkout session
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          guest_uid: location.state?.guest_uid,
          reading_map_url: readingMapUrl,
          success_url: successUrl,
          cancel_url: cancelUrl,
        }),
      });
  
      const session = await response.json();
  
      if (!response.ok || !session.id) {
        throw new Error('Failed to create Stripe Checkout session.');
      }
  
      // Redirect to Stripe Checkout
      window.location.href = session.url;
    } catch (err) {
      console.error('Error initiating purchase:', err);
      alert('An error occurred while processing your purchase.');
    }
  };

const tiers = [
  {
    name: 'Purchase this TarotMap',
    id: 'singleMap',
    price: '$3',
    description: 'Capture and reflect on what this reading revealed.',
    features: [
      'Emailed directly to you',
      'Download or share with your subject',
      'A single, secure purchase',
      'No sign-up or log-in required',
    ],
    featured: false,
  },
  {
    name: 'TarotMap Subscription',
    id: 'tier-account',
    price: '$5',
    description: 'Unlimited TarotMaps, to save and share.',
    features: [
      'Unlimited reading TarotMaps',
      'No hidden costs',
      'Single sign-in for easy access',
      'Cancel at any time',
    ],
    featured: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}



  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <img alt="TarotMap" src="/favicon.ico" className="mx-auto h-10 w-auto" />
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-base/7 font-semibold text-neutral-800">TarotMap</h2>
        <p className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
          Would you like to keep this TarotMap?
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg font-medium text-gray-600 sm:text-xl/8">
        You can simply purchase this TarotMap as a one-off, no need to sign up. Or you can opt for a subscription and access unlimited reading maps.
      </p>
      
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">

        {tiers.map((tier, tierIdx) => (
          <div
            key={tier.id}
            className={classNames(
              tier.featured ? 'relative bg-neutral-800 shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0',
              tier.featured
                ? ''
                : tierIdx === 0
                ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl'
                : 'sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none',
              'rounded-3xl p-8 ring-1 ring-gray-800/10 sm:p-10',
            )}
          >
            <h3
              id={tier.id}
              className={classNames(tier.featured ? 'text-orange-100' : 'text-orange-800', 'text-base/7 font-semibold')}
            >
              {tier.name}
            </h3>
            <p className="mt-4 flex items-baseline gap-x-2">
              <span
                className={classNames(
                  tier.featured ? 'text-white' : 'text-neutral-800',
                  'text-5xl font-semibold tracking-tight',
                )}
              >
                {tier.price}
              </span>
            </p>
            <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-6 text-base/7')}>
              {tier.description}
            </p>
            <ul
              className={classNames(
                tier.featured ? 'text-gray-300' : 'text-gray-600',
                'mt-8 space-y-3 text-sm/6 sm:mt-10',
              )}
            >
              {tier.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    aria-hidden="true"
                    className={classNames(
                      tier.featured ? 'text-orange-600' : 'text-orange-800',
                      'h-6 w-5 flex-none',
                    )}
                  />
                  {feature}
                </li>
              ))}
            </ul>
            {tier.id === 'singleMap' ? (
              <button
                onClick={handlePurchase}
                className={classNames(
                  'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold bg-orange-800 text-white shadow-xs hover:bg-orange-700 focus-visible:outline-orange-900',
                )}
              >
                Purchase
              </button>
            ) : (
              <a
                href="https://stripe.com" // Replace with your subscription link
                className={classNames(
                  'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold bg-orange-700 text-white shadow-xs hover:bg-orange-600 focus-visible:outline-orange-800',
                )}
              >
                Subscribe
              </a>
            )}
          </div>
        ))}
      </div>
      <p className="mt-10 text-center text-sm text-gray-500">
        Not ready to join yet?{' '}
        <a
          href="/"
          className="font-semibold text-orange-800 hover:text-orange-700">
          Exit here </a>
      </p>

      <p className="mt-10 text-center text-sm text-gray-100 hover:text-lime-300">
        Gimme it for{' '}
        <button
        onClick={handleViewMap}
        className= " text-gray-100 hover:text-lime-600"
      >
    FREE
        </button>
      </p>
    </div>
   
    
  );
};
export default Finalise;


