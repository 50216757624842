import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';



const Home = () => {
  const [readingTypes, setReadingTypes] = useState([]);
  const [selectedReadingType, setSelectedReadingType] = useState('');
  const navigate = useNavigate();

  // Fetch reading types from Supabase on component mount
  useEffect(() => {
    const fetchReadingTypes = async () => {
      try {
        const { data, error } = await supabase.from('reading_type').select('*');
        if (error) {
          console.error('Error fetching reading types:', error);
        } else {
          setReadingTypes(data);
        }
      } catch (err) {
        console.error('Unexpected error:', err);
      }
    };

    fetchReadingTypes();
  }, []);

  const handleStartReading = () => {
    if (selectedReadingType) {
      navigate('/reading-session', { state: { selectedReadingType } });
    } else {
      alert('Please select a reading type to proceed.');
    }
  };

  return (
    <div className="home-container py-12 max-w-md mx-auto">
      <img alt="TarotMap" src="/favicon.ico" className="mx-auto h-10 w-auto" />
      <h2 className="text-l mt-6 font-semibold text-neutral-700 text-center mb-4">Welcome to TarotMap</h2>
           <p className="text-center text-sm text-neutral-600 mt-20">Select a reading type to begin:</p>

      {/* Dropdown for selecting reading type */}
      <div className=" sm:mx-auto sm:w-full sm:max-w-sm">
      <div className="bg-white px-8 py-10  sm:rounded-lg sm:px-12">
      <select
        value={selectedReadingType}
        onChange={(e) => setSelectedReadingType(e.target.value)}
        className="rounded-lg text-sm w-full mb-4 p-3 border-neutral-300 focus:ring-orange-900/10 hover:bg-orange-900/5 focus:border-none"
      >
        <option value="" disabled>
          Reading types
        </option>
        {readingTypes.map((type) => (
          <option key={type.id} value={type.id}>
            {type.id}
          </option>
        ))}
      </select>
     <button
       type="button"
        onClick={handleStartReading}
        className="mt-4 flex w-full justify-center rounded-full bg-neutral-700 px-3 py-2.5 text-sm/6 font-semibold text-white shadow-xs hover:bg-orange-900/40 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-900"
      >
        Start Reading
      </button>
      </div>
    </div>
    </div>
  );
};

export default Home;